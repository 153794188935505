var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"w-full h-full bg-gray-100",attrs:{"id":"details"}},[_c('div',{staticClass:"h-full w-full overflow-scroll"},[_c('div',{staticClass:"mx-auto w-2/3 flex flex-col"},[_c('div',{staticClass:"sticky top-0 left-0 bg-gray-100 z-20"},[_c('h1',{staticClass:"my-title mb-6 text-center self-center"},[_vm._v(" "+_vm._s(_vm.$t("adminPanel.users.title"))+" ")]),(_vm.isLoading)?_c('div',{staticClass:"flex justify-center"},[_c('SpinerVue')],1):(!_vm.user)?_c('div',[_c('h1',[_vm._v(_vm._s(_vm.$t("adminPanel.users.select")))])]):[_c('div',{staticClass:"flex justify-between mb-5"},[_c('router-link',{attrs:{"to":{
                name: 'admin-users-detail-details',
                params: { idUser: _vm.user.id },
                hash: '#details',
              },"active-class":"text-my-blue-primary"}},[_vm._v(_vm._s(_vm.$t("adminPanel.users.details")))]),_c('router-link',{attrs:{"to":{
                name: 'admin-users-detail-locations',
                params: { idUser: _vm.user.id },
                hash: '#details',
              },"active-class":"text-my-blue-primary"}},[_vm._v(_vm._s(_vm.$t("adminPanel.users.userLocations")))]),_c('router-link',{attrs:{"to":{
                name: 'admin-users-detail-reviews',
                params: { idUser: _vm.user.id, CreatorOrReceiver: true },
                hash: '#details',
              },"active-class":"text-my-blue-primary"}},[_vm._v(_vm._s(_vm.$t("adminPanel.users.createdReviews")))]),_c('router-link',{attrs:{"to":{
                name: 'admin-users-detail-reviews',
                params: { idUser: _vm.user.id, CreatorOrReceiver: false },
                hash: '#details',
              },"active-class":"text-my-blue-primary"}},[_vm._v(_vm._s(_vm.$t("adminPanel.users.receivedReviews")))]),_c('router-link',{attrs:{"to":{
                name: 'admin-users-detail-payments',
                params: { idUser: _vm.user.id },
                hash: '#details',
              },"active-class":"text-my-blue-primary"}},[_vm._v(_vm._s(_vm.$t("adminPanel.users.payments")))])],1)]],2),_c('router-view',{staticClass:"z-10"})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }